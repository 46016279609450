var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"licenses-background"},[_c('el-container',[_c('el-header',{staticClass:"license-navigation-header"},[_c('RouterLink',{staticClass:"binalyze-logo",attrs:{"to":"/"}},[_c('VBinalyzeLogo',{staticClass:"mr-4",attrs:{"width":"80"}})],1),_c('span',{staticClass:"license-header-separator mr-4"}),_c('RouterLink',{attrs:{"to":"/license/licenses"}},[_c('span',{staticClass:"mr-4",class:{
            licenseActiveRoute: _vm.$route.name === 'LicenseLicenses',
            licenseLink: _vm.$route.name !== 'LicenseLicenses',
          }},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"table"}})],1),_vm._v("Licenses")])]),(_vm.hasPermission('versions-manage'))?_c('RouterLink',{attrs:{"to":"/license/versions"}},[_c('span',{staticClass:"mr-4",class:{
            licenseActiveRoute: _vm.$route.name === 'LicenseVersions',
            licenseLink: _vm.$route.name !== 'LicenseVersions',
          }},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"tasks"}})],1),_vm._v(" Versions")])]):_vm._e(),_c('RouterLink',{attrs:{"to":"/license/tags"}},[_c('span',{staticClass:"mr-4",class:{
            licenseActiveRoute: _vm.$route.name === 'LicenseTags',
            licenseLink: _vm.$route.name !== 'LicenseTags',
          }},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"tags"}})],1),_vm._v(" Tags")])]),(_vm.hasPermission('users-manage'))?_c('RouterLink',{attrs:{"to":"/license/users"}},[_c('span',{staticClass:"mr-4",class:{
            licenseActiveRoute: _vm.$route.name === 'Users',
            licenseLink: _vm.$route.name !== 'Users',
          }},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"users"}})],1),_vm._v(" Users")])]):_vm._e(),(_vm.hasPermission('tokens-manage'))?_c('RouterLink',{attrs:{"to":"/license/tokens"}},[_c('span',{staticClass:"mr-4",class:{
            licenseActiveRoute: _vm.$route.name === 'Tokens',
            licenseLink: _vm.$route.name !== 'Tokens',
          }},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"key"}})],1),_vm._v(" API Tokens")])]):_vm._e(),_c('a',{staticClass:"mr-4 logoutLink",staticStyle:{"margin-left":"auto"},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-1"},[_c('fa-icon',{attrs:{"icon":"sign-out-alt"}})],1),_vm._v(" Logout")])],1)],1),_c('el-container',{staticClass:"license-pages-templates"},[_c('RouterView')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }