<template>
  <el-dialog
    title="License Details"
    :visible.sync="showPanel"
    center
    width="1200px"
    top="1vh"
    align-center="true"
    :before-close="closeNewLicensePanel"
  >
    <div v-loading="$wait.is($waiters.License.License)">
      <div class="license-details-copiable-key">
        {{ this.detailKey }}<span @click="copyLicenseToClipboard"><fa-icon :icon="['far', 'copy']" /></span>
      </div>
      <div class="license-details-copiable-key-confirm">
        <transition name="fade">
          <span v-if="copyActionCompleted">License Key Copied</span>
        </transition>
        <span>&nbsp;</span>
        <span v-if="cannotCopyAction">Error while License Key Copy</span>
      </div>
      <el-divider class="license-details-divider license-details-info-label">License Details</el-divider>

      <el-tabs type="card"
        ><!--@tab-click="handleClick">-->
        <el-tab-pane label="General"
          ><license-general-info @reload="onReload" :license-details="license"></license-general-info
        ></el-tab-pane>
        <el-tab-pane label="Servers"
          ><license-devices-info @reload="onReload" :license-details="license"
        /></el-tab-pane>
        <el-tab-pane label="History"><license-history-info :license-details="license" /></el-tab-pane>
        <el-tab-pane label="Quotas"><LicenseQuotasInfo @reload="onReload" :license-details="license" /></el-tab-pane>
      </el-tabs>

      <div class="license-details-panel-footer">
        <div class="license-details-action-buttons-left">
          <el-button type="primary" size="small" @click="onClickDownload"
            ><fa-icon :icon="['fas', 'download']"
          /></el-button>
          <el-button type="primary" size="small" @click="generatePDF()"><fa-icon :icon="['fas', 'table']" /></el-button>
        </div>
        <div class="license-details-action-buttons-right">
          <el-button size="small" v-if="hasPermission('license-manage')" type="danger" @click="showDeleteLicense()"
            >Delete</el-button
          >
          <el-button size="small" @click="closeNewLicensePanel()">Back</el-button>
        </div>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="10000"
      :filename="license.companyName + '-' + license.key"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      ref="html2Pdf"
    >
      <license-pdf :license="license" slot="pdf-content" />
    </vue-html2pdf>

    <license-delete-panel
      :license-key="detailKey"
      @close="deleteLicensePanelVisible = false"
      :show="deleteLicensePanelVisible"
      @deleted="deleteLicense"
    />
  </el-dialog>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import LicenseGeneralInfo from '@/views/License/children/subComp/licenseViewTabs/licenseGeneralInfo.vue'
import LicenseDevicesInfo from '@/views/License/children/subComp/licenseViewTabs/licenseDevicesInfo.vue'
import LicenseHistoryInfo from '@/views/License/children/subComp/licenseViewTabs/licenseHistoryInfo.vue'
import LicensePdf from '@/views/License/children/subComp/licensePdf.vue'
import { LicenseFilteredRequestDto } from '@/api/Services/License/dto/request/license-filtered-request.dto'
import { LicensesService } from '@/api/Services/License/LicensesService'
import LicenseQuotasInfo from '@/views/License/children/subComp/licenseViewTabs/licenseQuotasInfo.vue'
import { downloadFile } from '@/utils/Helpers'
import LicenseDeletePanel from '@/views/License/children/subComp/licenseViewTabs/licenseDeletePanel.vue'

export default {
  name: 'LicenseDetailsPanel',

  components: {
    LicenseDeletePanel,
    LicenseQuotasInfo,
    LicenseGeneralInfo,
    LicenseDevicesInfo,
    LicenseHistoryInfo,
    VueHtml2pdf,
    LicensePdf,
  },

  data() {
    return {
      showPanel: this.panelDisplayStatus,
      cannotCopyAction: false,
      copyActionCompleted: false,
      activeTab: 'first',
      license: {},
      deleteLicensePanelVisible: false,
    }
  },

  props: {
    panelDisplayStatus: Boolean,
    detailKey: String,
    environment: Number,
  },

  watch: {
    panelDisplayStatus: function () {
      this.updateShowPanelStatus()
    },

    detailKey: function () {
      this.fetchLicense()
    },
  },

  methods: {
    updateShowPanelStatus() {
      this.showPanel = this.panelDisplayStatus
    },

    closeNewLicensePanel() {
      this.showPanel = false
      this.$emit('displayStatus', this.panelDisplayStatus)
    },

    copyLicenseToClipboard: function () {
      this.$copyText(this.detailKey).then(
        () => {
          this.copyActionCompleted = true
          setTimeout(
            function () {
              this.copyActionCompleted = false
            }.bind(this),
            2500,
          )
        },
        (error) => {
          this.cannotCopyAction = true
          setTimeout(
            function () {
              this.cannotCopyAction = false
            }.bind(this),
            2500,
          )
          console.log(error)
        },
      )
    },

    onProgress(event) {
      console.log(`Processed: ${event} / 100`)
    },

    generatePDF() {
      this.$refs.html2Pdf.generatePdf()
    },

    onClickDownload() {
      downloadFile('license.txt', this.license.key)
    },

    onReload: async function () {
      this.$wait.start(this.$waiters.License.License)
      setTimeout(() => {
        this.$emit('reload')
        this.fetchLicense()
        this.$wait.end(this.$waiters.License.License)
      }, 500)
    },

    fetchLicense() {
      this.$request(async () => {
        const req = new LicenseFilteredRequestDto()
        req.key = this.detailKey
        req.environment = parseInt(this.environment)
        const { data } = await LicensesService.getLicensesSummary(req)
        if (data.success) {
          this.license = data.result.entities[0]
        }
      }, this.$waiters.License.License)
    },

    showDeleteLicense() {
      this.deleteLicensePanelVisible = true
    },

    deleteLicense() {
      this.$request(async () => {
        await LicensesService.deleteLicense(this.detailKey)
        this.$emit('reload')
        this.closeNewLicensePanel()
      }, this.$waiters.License.License)
    },
  },
}
</script>

<style lang="scss">
.license-details-divider.license-details-info-label {
  margin-top: 0px !important;
}

.license-details-copiable-key {
  width: fit-content;
  border: 1px solid $color-light-gray;
  margin-bottom: 20px;
  margin-top: -20px;
  font-size: larger;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0px 0 10px;
  align-items: center;
}

.license-details-copiable-key-confirm {
  width: fit-content;
  margin-bottom: 20px;
  margin-top: -20px;
  font-size: small;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0px 0 10px;
  align-items: center;
}

.license-details-copiable-key > span {
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  font-size: small;
  border-left: 1px solid $color-light-gray;
}

.license-details-copiable-key > span:hover {
  cursor: pointer;
}

.license-details-copiable-key > span:active {
  background-color: $color-dark-10;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.license-details-panel-footer {
  border-top: solid 1px $color-light-gray;
  padding-top: 25px;
  margin-top: 25px;
  margin-bottom: -10px;
  display: grid;
  grid-template-columns: 86% 14%;
}
</style>
